import React, {useState, lazy} from 'react';
import styled from "styled-components";
import { Canvas } from '@react-three/fiber';
import { Suspense } from 'react';
import Earth from './EarthComponent';
import TextSection from './TextSection';
import scrollImg from '../assests/scroll.png';
import { FaCloudSun } from "react-icons/fa";
import { FaCloudMoon } from "react-icons/fa";
const Experience = lazy(() => import("./experience/Experience"));

const MainComponent = () => {

  const [state, setState] = useState(true);

    return(
        <>
      <CanvasContainer>
        <TextSection/>
        <Canvas>       
         <Suspense fallback={false}>
            <Earth state={state}/>
          </Suspense> 
        </Canvas>
        <Scroll>
            <p>Scrool Down</p>
            <img src={scrollImg} alt="scroll" />
        </Scroll>
        <ToggleClimate>
          <p>{state ? "Morning": "Night"}</p>
          {/* <input type="checkbox" id="toggle"/>
	        <label htmlFor="toggle" onClick={() => setState(!state)}></label> */}
          {state ? <FaCloudSun height="1.5em" style={{color: 'yellow'}} onClick={() => setState(!state)}/> : <FaCloudMoon style={{color: 'white'}} onClick={() => setState(!state)}/>}
        </ToggleClimate>
      </CanvasContainer>
      <Suspense fallback={<div>Loading....</div>}>
       <Container>
         <Experience />
       </Container>
      </Suspense>    
    </>
    )
}

const CanvasContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #010401;
`;

const Container = styled.div`
`;

const Scroll = styled.div`
position: absolute;
color: #fff;
bottom: 0;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;

@media only screen and (max-width: 320px) {
  p{
    font-size: 10px;
  }
}

img {
  margin: 10px;
  width: 20px;
  height: 20px;
  animation: fade_move_down 2s ease-in-out infinite;

  @media only screen and (max-width: 320px) {
    width: 10px;
    height: 10px;
  }
}

@keyframes fade_move_down {
  0%   { transform:translate(0,-40px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { transform:translate(0,10px); opacity: 0; }
}
`;

const ToggleClimate = styled.div`
position: absolute;
right: 20px;
top: 20%;
color: #fff;
display: flex;
align-items: center;
justify-content: center;

@media only screen and (max-width: 320px) {
  top: 15%;
}

svg {
width: 30px;
height: 30px;
margin-left: 10px;
cursor: pointer;
}
`;

export default MainComponent;