import React from 'react';
import styled from 'styled-components';
import { ProjectContainer } from './ProjectContainer';
import Tesla from '../../assests/project-tesla.png';
import Netflix from '../../assests/netflix-project.png';
import Dashboard from '../../assests/Dashboard.png';
import Shop from '../../assests/shop_cart.png';
import World from '../../assests/3dworld.png';
import Todo from '../../assests/todoapp.png';

export const Project = () => {
  return (
    <CanvasContainer>
      <h2>PROJECTS</h2>
      <List>
      <ProjectContainer img={Tesla} text="Tesla Clone Project" url="https://tsdev-tesla.netlify.app/"/>
      <ProjectContainer img={Netflix} text="Netflix Clone Project" url="https://tsdev-netflix.netlify.app/"/>
      <ProjectContainer img={Dashboard} text="Responsive DashBoard" />
      <ProjectContainer img={Shop} text="React Shopping Cart" url="https://react-ts-6hrngz.stackblitz.io/"/>
      <ProjectContainer img={World} text="ThreeJs World" url="https://ut6sz6.csb.app/"/>
      <ProjectContainer img={Todo} text="Todo App" url="https://tamilselvan-dev.github.io/DragDrop-and-Todo/"/>
      </List>
      
    </CanvasContainer>
  )
}

const CanvasContainer = styled.div`
  position: absolute;
  top:20%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: #010401;
  text-align: center;
  color: #fff;
  transition: all 500ms ease-in-out;

  h2 {
    width: 200px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  }
  
`;

const List = styled.div`
width: 100%;
height: 100%;
display:flex;
flex-wrap: wrap;
align-items: center;
justify-content: center;
margin-top: 40px;`;


