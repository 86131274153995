import React from 'react';
import styled from 'styled-components';


const Card = (props) => {
  return (
    <CardContainer>
      <Icon><img src={props.img} alt={props.domain}/></Icon>
      <Text><a href={props.domain === "mail"? `mailto:${props.name}` : props.name} target="_blank" rel="noopener noreferrer">{props.name}</a></Text>
    </CardContainer>
  )
}

const CardContainer = styled.div`
margin-left: 60px;
display: flex;
justify-content: flex-start;
align-items: center;
@media only screen and (max-width: 414px) {
      margin:0;
      flex-direction: column;
`;

const Icon = styled.div`
padding: 10px;

img {
    width: 35px;
    height: 35px;

    @media only screen and (max-width: 414px) {
      width: 35px;
      height: 35px;
    }
}`;

const Text = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
font-size: 20px;
font-weight: 800;
transition: all 0.2s ease-out;

a {
  position: relative;
  display: inline-block;
  font-size: 20px;
  font-weight: 400;

  @media only screen and (max-width: 414px) {
      font-size: 15px;
    }
}

a:after {
  content: "";
  position: absolute;
  left:0;
  top: 100%;
  width: 0;
  height: 2px;
  background-color: #51f565;
  transition: width .9s ease-in-out;
}
a:hover:after {
  width: 100%;
}
`;

export default Card;