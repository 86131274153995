import React, { useState } from "react";
import styled from "styled-components";
import logo from "../assests/logo.svg";
import Resume from '../assests/Tamilselvan-resume-dev.pdf'
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { FcLowPriority } from "react-icons/fc";
import { FcFlashOff } from "react-icons/fc";
import { FcFlashOn } from "react-icons/fc";

const NavBar = () => {
  const [backgroundColor, setBackGroundColor] = useState('');
  const [menuState, setmenuState] = useState(false);

  return (
  <>
  <NavBarContainer color={backgroundColor}> 
      <Logo>
       <Link to="/"><img src={logo} alt="logo" /></Link>
      </Logo>
      <NavList>
        <ul>
          <li><CustomLink to="/" onClick={() => setBackGroundColor('')}>Home</CustomLink></li>
          <li><CustomLink to="/projects" onClick={() => setBackGroundColor('black')}>Projects</CustomLink></li>
          <li><CustomLink to="/Skills" onClick={() => setBackGroundColor('black')}>Skils</CustomLink></li>
          <li><CustomLink to="/Contact" onClick={() => setBackGroundColor('black')}>Contact</CustomLink></li>
        </ul>
        <Anchor href={Resume} target="_blank" rel="noopener noreferrer">Resume<FcLowPriority />
       </Anchor>
      </NavList>
    </NavBarContainer>
    <NavBarMobile>
      {menuState ? <FcFlashOn onClick={() => setmenuState(!menuState)}/> : <FcFlashOff onClick={() => setmenuState(!menuState)}/> }
      {
        menuState ?
        <MobileContainer>
      <ul>
        <li><CustomLink to="/" onClick={() => setBackGroundColor('')}>Home</CustomLink></li>
        <li><CustomLink to="/projects" onClick={() => setBackGroundColor('black')}>Projects</CustomLink></li>
        <li><CustomLink to="/Skills" onClick={() => setBackGroundColor('black')}>Skils</CustomLink></li>
        <li><CustomLink to="/Contact" onClick={() => setBackGroundColor('black')}>Contact</CustomLink></li>
      </ul> 
        </MobileContainer>
       
      : null
      }
   
    </NavBarMobile>
  </>
    
  );
};

function CustomLink({ to, children, ...props}) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({path: resolvedPath.pathname, end: true})

  return <Link className={isActive ? 'navActive' : ''} to={to} {...props}>{children}</Link>
}

export default NavBar;

const NavBarContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 99; 
  overflow: hidden;
  background-color: ${props =>props.color};
  opacity: 1;
  transition: transform 1s ease-out;

  @media only screen and (max-width: 414px) {
    display: none;
    transition: transform 1s ease-out;
}
`;

const Logo = styled.div`
  padding: 10px;

  img{
    width: 40%;
    height: 40%;
  }
`;

const NavList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  transition: transform 1s ease-out, visibility 0s;
  visibility: visible;

  ul {
    display: flex;
    justify-content: space-around;
    align-items: center;  
  }

  li {
    padding: 10px 20px;
    color: white;
    font-weight: 700;
    transition: all 0.2s ease-out;

    a:hover {
      color: #51f565;
    }
  }`;

const Anchor = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  overflow: hidden;
  border: solid 1px #fff;
  padding: 3px 10px;
  border-radius: 10px;

  svg {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    
  }

  &:hover{
    color: #51f565;
    border: solid 1px #fff;
  }

  &:hover svg {
    animation: download infinite 1000ms ease-in;
  }

  @keyframes download {
    from { transform : translateY(-20px)}
    to {transform : translateY(20px)}
  }
  `;

const NavBarMobile = styled.div`
display: none;

@media only screen and (max-width: 414px) {
  position: absolute;
display: inline-flex;
z-index: 99;
transition: transform 5s ease-out;

svg {
  width: 30px;
  height: 30px; 
  margin: 10px;
}
}
`;

const MobileContainer = styled.div`
 width: 100%;
 height: 100%;
 padding: 10px;

 ul {
  display: flex;
  flex-direction: row;
  gap: 10px;
 }
`
