import React from 'react';
import styled from 'styled-components';

export const ProjectContainer = ({img, text, url}) => {
  return (
    <Container img={img}>
        <TextArea>
            <h3>{text}</h3>
            {url &&
            <a href={url} target="_blank" rel="noopener noreferrer">Visit Website</a>
             }        
        </TextArea>
    </Container>
  )
}

const TextArea = styled.div`
position: absolute;
width: 100%;
bottom:-300px;
background-color: black;
height: 40%;
border-radius: 0 0 50px 50px;
opacity: 0.9;
transition: bottom 0.8s ease;

h3 {
  padding: 10px 15px;
  font-size: 20px;
}

a {
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 10px;
    font-size: 15px;
    padding: 5px 10px;

    &:hover {
      color: #000;
      background-color: #51f565;
    }
}`;

const Container = styled.div`
position: relative;
min-width: 500px;
min-height: 300px;
margin: 20px;
background: url(${props => props.img});
background-position: 100%;
background-repeat: no-repeat;
background-size: 100%;
border-radius: 20px 20px 50px 50px;
box-shadow: 4px -7px 30px -3px #777777;
overflow:hidden;
cursor: pointer;

@media only screen and (max-width: 414px) {
  min-width: 400px;
}

&:hover ${TextArea} {
  bottom:0;
}
`;



