import "./App.css";
import MainComponent from './Component/MainComponent';
import NavBar from './Component/Navbar';
import {Project} from './Component/project/Project';
import {Skills} from './Component/skills/Skills';
import {Contact} from './Component/contact/Contact';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Footer } from "./Component/Footer";

export default function App() {

  return (
    <>
    <Router>  
      <NavBar />
      <Routes>
        <Route exact path="/" element={<MainComponent />} />  
        <Route path="/projects" element={<Project />} />  
        <Route path="/skills" element={<Skills />} />  
        <Route path="/contact" element={<Contact />} />  
      </Routes>  
      <Footer />
    </Router>
    
    </>
  );
}

