import React , { useRef } from 'react';
import { Stars } from '@react-three/drei';
import { useFrame } from "@react-three/fiber";

export const StarsMesh = () => {

  const starsRef = useRef(null);

  useFrame(({ clock })=> {
  const time = clock.getElapsedTime();
  starsRef.current.rotation.y = time / 6;
  });

  return (
    <>
    <Stars ref={starsRef} radius={200} depth={20} count={8000} factor={10} fade={true} />
    </>
    
  )
}

export default StarsMesh;
