import React, { useRef } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import earth from "../assests/world.jpg";
import cloud from "../assests/clouds.jpg";
import negativeMap from "../assests/earth-negative-map.jpg";
import nightMap from "../assests/earth-night-map.jpg";
import terrainMap from "../assests/earth-terrain-map.jpg";
import moon from "../assests/moon.png";
import { DoubleSide, TextureLoader } from 'three';
import { OrbitControls, Stars } from "@react-three/drei";

const Earth = (props) => {

  const [colorMap,
    cloudMap,
    NightMap,
    TerrainMap,
    NegativeMap,
    Moon
  ] = useLoader(TextureLoader, [
    earth,
    cloud,
    nightMap,
    terrainMap,
    negativeMap,
    moon
  ]);

  const earthRef = useRef(null);
  const cloudRef = useRef(null);
  const moonRef = useRef(null);

  useFrame(({ clock })=> {
  const time = clock.getElapsedTime();
  earthRef.current.rotation.y = time / 6;
  cloudRef.current.rotation.y = time / 6;
  moonRef.current.rotation.y = time / 6;
  });


    return(
        <>
        <ambientLight intensity={props.state? 3 : 4} /> 
        {/* <pointLight color="#f6f3ea" position={props.state?[1, 0, 15] : [1, 0, 20]} intensity={props.state? 3 : 4} /> */}
  
        <Stars radius={300} depth={60} count={8000} factor={7} fade={true} />
        
        <mesh ref={cloudRef}>
          <sphereGeometry args={[2, 32, 32]} />
          <meshPhongMaterial
            map={cloudMap}
            opacity={props.state? 0.4 : 0.1}
            depthWrite={true}
            transparent={true}
            side={DoubleSide}
          />
        </mesh>
  
        <mesh ref={earthRef}>
          <sphereGeometry args={[2, 32, 32]} />
          <meshPhongMaterial specularMap={NegativeMap} />
          <meshStandardMaterial
            map={props.state ? colorMap : NightMap}
            normalMap={TerrainMap}
            metalness={0.4}
            roughness={0.7}
          />
          <mesh ref={moonRef} position={[5, 1, -5]}>
           <sphereBufferGeometry attach="geometry" args={[1, 30, 30]} />
           <meshStandardMaterial attach="material" color="gray" map={Moon} />
          </mesh>
          <OrbitControls
            enableZoom={false}
            enablePan={true}
            zoomSpeed={0.6}
            panSpeed={0.5}
            rotateSpeed={0.5}
          />
        </mesh>
      </>
  
    )
};

export default Earth;