import React from 'react';
import styled from 'styled-components';
import { Card } from './Card';
import atom from './skillAssests/atom.png';
import html from './skillAssests/html.png';
import css from './skillAssests/css-3.png';
import javascript from './skillAssests/js.png';
import node from './skillAssests/node-js.png';
import sql from './skillAssests/sql-server.png';
import mongodb from './skillAssests/mongodb.png';
import figma from './skillAssests/figma.png';
import angular from './skillAssests/angular.png';
import seo from './skillAssests/seo.png';
import docker from './skillAssests/docker.png';
import npm from './skillAssests/npm.png';
import postman from './skillAssests/postman.png';
import vscode from './skillAssests/vscode.png';
import json from './skillAssests/json.png';
import api from './skillAssests/api.png';
import python from './skillAssests/python.png';
import githup from './skillAssests/github.png';
import graphql from './skillAssests/graphql.png';
import springboot from './skillAssests/springboot.png';

import '../../App.css';

export const Skills = () => {

  return (
    <>
      <CanvasContainer>
        <Title><h2>SKILLS</h2></Title>
        <Section>
        <Card name="React" img={atom} color="#00bcf0"/>
        <Card name="Angular" img={angular} color="#ff0000"/>
        <Card name="Html" img={html} color="#ff7816"/>
        <Card name="Css" img={css} color="#0564af"/>
        <Card name="Javascript" img={javascript} color="#ffdf00"/>
        <Card name="Node Js" img={node} color="#62b549"/>
        <Card name="Python" img={python} color="#5d8cb4"/>
        <Card name="SpringBoot" img={springboot} color="#6bb445"/>
        <Card name="Sql" img={sql} color="#dea806"/>
        <Card name="mongodb" img={mongodb} color="#ffffff"/>
        <Card name="Seo" img={seo} color="#d35494"/>
        <Card name="Api" img={api} color="#1c75a1"/>
        <Card name="Json" img={json} color="#a3a3a3"/>
        <Card name="GraphQL" img={graphql} color="#fff"/>
        </Section>

        <Title><h2>TOOLS</h2></Title>
        <Section>
        <Card name="githup" img={githup} color="#b659ff"/>
        <Card name="figma" img={figma} color="#b9b9b9"/>
        <Card name="docker" img={docker} color="#bdd9d7"/>
        <Card name="npm" img={npm} color="#d66564"/>
        <Card name="vscode" img={vscode} color="#5f266d"/>
        <Card name="postman" img={postman} color="#ffffff"/>
        </Section>

      

      </CanvasContainer>
    </>
  )
}

const CanvasContainer = styled.div`
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  margin-bottom: 1%;
  background-color: black;
  color: #fff;
`;

const Title = styled.div`
width: 100%;
height: 30px;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
margin: 120px 0 50px 0;


h2 {
  width: 200px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 414px) {
   margin-top: 30px;
  }
}
`;

const Section = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-wrap: wrap;`;
