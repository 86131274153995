import React from 'react';
import styled from 'styled-components';
import profile from '../../assests/profile-pic.jpg';
import Typed from "react-typed";
import { Canvas } from '@react-three/fiber';
import { Suspense } from 'react';
import Stars from './StarsMesh';
import Card from './Card';
import Gmail from '../../assests/gmail.png';
import Linkedin from '../../assests/linkedin.png';
import Logo from '../../assests/logo.svg';
import Codepen from '../../assests/codepen.svg';
import Github from '../../assests/github.png';

export const Contact = () => {

  return (
    <>
      <CanvasContainer>
        <Container>
          <PhotoContainer>
             <Cirlce>
               <img src={profile} alt="profile_picture" />
             </Cirlce>
             <div>
             <h2>Tamilselvan Velayudham</h2>
             <h4><Typed
        style={{color: '#51f565'}}
        strings={[
          "Front End Developer",
          "Full Stack Developer",
          "SEO Developer",
          "Technical Specilaist"
        ]}
        typeSpeed={80}
        backSpeed={60}
        loop
      /></h4>
      <img src={Logo} alt="logo" />
             </div>
          </PhotoContainer>
          <DetailsContainer>
          <Canvas>
          <Suspense fallback={false}>
            <Stars />
          </Suspense>
        </Canvas>
            <TextContainer>
               <h2>Contact us</h2>
               <p>Please check with below domains to contact me! Thank You</p>
                <Card name="tamilselvan.selvan332@gmail.com" img={Gmail} domain="mail"/> 
                <Card name="https://www.linkedin.com/in/tamilselvan-velayudham/" img={Linkedin} domain="link"/> 
                <Card name="https://codepen.io/tamilselvan-dev" img={Codepen} domain="link"/> 
                <Card name="https://github.com/Tamilselvan-dev" img={Github} domain="link"/> 
            </TextContainer>
          </DetailsContainer>
        </Container>
      </CanvasContainer>
    </>
  )
}

const CanvasContainer = styled.div`
  position: absolute;
  top: 15%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  background-color: black;
  color: #fff;`;

const Container = styled.div`
background-color: white;
width: 100%;
height: 100%;
color: black;
background-color: black;
display: grid;
grid-template-columns: 400px auto;

@media only screen and (max-width: 414px) {
  grid-template-rows: 400px auto;
  grid-template-columns: none;
    }`;

const PhotoContainer = styled.div`
display: flex;
align-items: center;
margin-top: 50px;
flex-direction: column;
color: #fff;

h4 {
  text-transform: uppercase;
  padding: 10px 20px;
}

img {
  width: 100px;
  height: 40px;
}

div {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}
`;

const DetailsContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
position: relative;

@media only screen and (max-width: 414px) {
      min-width: 100%;
      min-height: 100vh;
    }
`;

const Cirlce = styled.div`
border: 10px solid #51f565;
border-radius: 50%;

img {
  width: 200px;
  height: 200px;  
  border-radius: 50%;
  background-size: contain;
}`;

const TextContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: white;
  padding: 50px;

  h2 {
    font-size: 40px;
    font-weight: 500;
    color: #51f565;
    text-decoration: underline;

    @media only screen and (max-width: 414px) {
      font-size: 20px;
      text-align: center;
      padding:10px;
      display: none;
    }
  }

  p {
    padding: 20px 0 20px 60px;
    font-size: 20px;
    @media only screen and (max-width: 414px) {
      font-size: 20px;
      text-align: center;
      padding: 0;
    }
  }
`;

/* const Icons = styled.div``; */

