import React from 'react'
import styled from 'styled-components';
import Icon from "../assests/heart.png";
import { FaCopyright } from "react-icons/fa";

export const Footer = () => {
  return (
    <FooterContainer>
        <Container><p>TS CODER </p><span><img src={Icon} alt="footerlogo" /></span></Container>
        <Container>
           <p><FaCopyright /> Copyrights 2022</p>
        </Container>
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
background-color: #000;
display: flex;
justify-content: space-around;
align-items: center;
border-top: solid 3px #fff;
height: 10%;

`;

const Container = styled.div`
  padding: 20px;
  margin: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  svg {
    margin-right: 10px;
  }
  }

span{
    img{
      margin-left: 10px;
        width: 15px;
        height:15px;
    }
}`;

