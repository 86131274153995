import React from "react";
import styled from "styled-components";
import Typed from "react-typed";

const TextSection = () => {
  return (
    <TopContainer>
      <Logo>Hello!</Logo>
      <Logo>This is Tamilselvan Velayudham</Logo>
      <Logo>I'm <Typed
        className="typewriter"
        strings={[
          "Full Stack Developer",
          "SEO Developer",
          "Engineer"
        ]}
        typeSpeed={150}
        backSpeed={100}
        loop
      /></Logo>
      
      <Description>One of the developer out there in this <Span>#3D World</Span> to <Span>#Hire</Span></Description>
    </TopContainer>
  );
};

export default TextSection;

const TopContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const Logo = styled.h1`
  margin: 0;
  color: #fff;
  font-weight: 700;
  font-size: 35px;

@media only screen and (max-width: 320px) {
  font-size: 16px;

  .typewriter{
    font-size: 16px;
  }
} 

@media only screen and (max-width: 414px) {
  font-size: 19px;

  .typewriter{
    font-size: 19px;
  }
} 
`;

const Description = styled.div`
  color: #fff;
  font-size: 20px;
  margin-top: 2em;

@media only screen and (max-width: 320px) {
  font-size: 16px;
  text-align: center;
}

@media only screen and (max-width: 414px) {
  font-size: 18px;
  text-align: center;
}
`;

const Span = styled.h5`
  color: #fff;
  display: inline;
  font-weight: 700;
  padding: 5px;
  border-radius: 10px;
  animation: fade 4s ease-in-out infinite;

  @media only screen and (max-width: 320px) {
    text-align: center;
    display: block;
}

@media only screen and (max-width: 414px) {
    text-align: center;
    display: block;
}

  @keyframes fade {
  0%   { background: red; opacity: 0;  }
  50%  { opacity: 1;  }
  100% { background: red; opacity: 0; }
}
  
`;