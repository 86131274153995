import React from 'react';
import styled from 'styled-components';

export const Card = (props) => {
  return (
    <CardContainer>
      <TopContainer>
      <CircleWrapper>
        <Circle color={props.color}/>
      </CircleWrapper>
      <ImageWrapper>
        <Icon  style={{ rotate: "o" }}> 
          <img src={props.img} alt={props.name} />
        </Icon>
      </ImageWrapper> 
      </TopContainer>
      <BottomContainer><ContainerText>{props.name}</ContainerText></BottomContainer>
    </CardContainer>
  )
}


const CardContainer = styled.div`
width: 285px;
height: 350px;
display: flex;
flex-direction: column;
border-radius: 25px;
box-shadow: 0 2px 7px 1px rgba(31, 31, 31, 0.2);
background-color: #1d1f21;
color: #fff;
position: relative;
cursor: grap;
margin: 20px;
transition: all 1s ease-in-out;
cursor: pointer;

&:hover{
  transform: perspective(1000px) translate3d(10px, 0, 100px);
  /* box-shadow: 5px 3px 3px 5px #51f565; */
  box-shadow: 0 0 5px 2px #fff;
}`;

const CircleWrapper = styled.div`
 position: absolute;
 top: 0;
 left: 0;
 min-width: 100%;
 min-height: 100%;
 overflow: hidden;
 border-top-right-radius: 25px;
`;

const Circle = styled.div`
  position: absolute;
  width: 350px;
  height: 350px;
  top: -4.2em;
  right: -10em;
  z-index: 5;
  background-color: ${({color})=>color};;
  border-radius: 50%;
`;

const TopContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
flex: 1.2;
position: relative;
align-items: center;
justify-content: flex-end;
padding: 1em 15px;
`;

const BottomContainer = styled.div`
display: flex;
flex: 0.8;
padding: 0 1em;
align-items: center;
justify-content: center;
`;

const ContainerText = styled.div`
color: #fff;
text-transform: uppercase;
margin-top: 10px;
z-index: 10;
font-size: 40px;
font-weight: 900;
`;

const ImageWrapper = styled.div`
width: 100%;
height: 100%;
position: absolute;
display: flex;
align-items: center;
justify-content: center;
`;

const Icon = styled.div`
width: auto;
height: 190px;
z-index: 99;
margin-top: 20px;

img {
  width: auto;
  height: 100%;
  user-select: none;
  transition: all 400ms ease-in;
}
`;
